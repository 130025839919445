<template>
  <div v-if="Behind">
    <HeroText :title="Behind.behindTitle" :subtitle="Behind.behindSubtitle" />
    <div
      v-for="({title, text, reverse}, i) in Behind.behindContent"
      :key="i"
      ref="contents"
      class="container mb-8 md:mb-16"
    >
      <div class="row">
        <div
          class="col-lg-6 mb-8 flex flex-col justify-end"
          :class="{
            'lg:order-2' : reverse,
            'offset-xl-1': reverse
          }"
        >
          <h3 class="big text-secondary">
            <template
              v-for="(word,i) in title.split(' ')"
            >
              <span
              :key="i"
              class="word stagger"
              >
                {{word}}
              </span>
              <span
              :key="'space'+i"
              class="whitespace"
              > </span>
            </template>
          </h3>
        </div>
        <div
          class="col-lg-6 col-xl-5 mb-8 flex flex-col justify-end"
          :class="{
            'lg:order-1' : reverse,
            'offset-xl-1': !reverse
          }"
        >
          <MarkdownBlock class="intro-content stagger" :text="text" />
        </div>
      </div>
    </div>
    <div class="container">
      <h4 class="label text-secondary">{{Behind.behindJuryTitle}}</h4>
    </div>
    <AccordionList :list="Behind.behindJuryList">
      <template v-slot:header="{ item, index }">
        <h3 class="big stagger" :data-name="item.name">{{item.name}}</h3>
        <div class="plus-icon"></div>
        <div class="image-header" :style="{'z-index': index + 2}">
          <ResponsiveImage :data="item.image" />
        </div>
      </template>
      <template v-slot:content="{ item, index }">
        <ResponsiveImage class="image-content" :data="item.image" />
        <MarkdownBlock class="pt-8" :text="item.description" />
        <div class="video-wrapper mt-8" v-if="item.video">
          <VideoPlayer :ref="`video${index}`" :video="item.video" @playing="onPlaying(index)"/>
        </div>
      </template>
    </AccordionList>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { MarkdownBlock } from '@monogrid/vue-lib'
import ShareMixin from '@/mixins/share-mixin'
import WaitLoader from '@/mixins/wait-loader'
import GetBehind from '@/graphql/GetBehind.gql'
import HeroText from '@/atoms/HeroText'
import AccordionList from '@/atoms/AccordionList'
import ResponsiveImage from '@/atoms/ResponsiveImage'
import VideoPlayer from '@/molecules/VideoPlayer'

export default {
  name: 'BehindTheAwardPage',
  mixins: [ShareMixin, WaitLoader(500)],
  components: { HeroText, AccordionList, MarkdownBlock, ResponsiveImage, VideoPlayer },
  apollo: {
    Behind: {
      query: GetBehind,
      variables () {
        return {
          status: 'PUBLISHED',
          id: '757eb7dc-6ba0-4905-8bd1-5a19a03e1fa2',
          variants: [this.$language.current === 'en' ? 'base' : this.$language.current]
        }
      }
    }
  },
  beforeDestroy () {
    this.tl && this.tl.forEach(tl => tl.kill())
  },
  watch: {
    Behind (value) {
      if (value) {
        this.$root.preloader.visible = false
        this.$nextTick(this.createTl)
      }
    }
  },
  methods: {
    createTl () {
      this.tl = []

      this.$refs.contents.forEach(el => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: (this.$root.platform.mobile || this.$root.platform.tablet) ? 'top center' : 'top bottom-=50'
          }
        })
        this.tl.push(tl)
        this.checkLoaderState()
        tl.fromTo(el.querySelectorAll('.stagger'), { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, stagger: 0.1 })
        tl.fromTo(el.querySelectorAll('.word'), { y: 20 }, { y: 0, duration: 0.5, stagger: 0.1 }, '<')
      })
    },
    onPlaying (index) {
      if (Number.isInteger(this.lastPlayed) && index !== this.lastPlayed && this.$refs[`video${this.lastPlayed}`]) {
        this.$refs[`video${this.lastPlayed}`].pause()
      }
      this.lastPlayed = index
    }
  },
  metaInfo () {
    if (this.Behind) {
      return this.getShare({
        title: this.Behind.behindTitle
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  position: relative;

  z-index: 1;

  transition: color 0.1s;

  &::after {
    @include themify-rule('color', 'secondary', 'behind');
    position: absolute;
    top: 0;
    left: 0;

    clip-path: polygon(0 100%, 0 100%, 0 100%, 0 100%);

    content: attr(data-name);

    opacity: 0;
    transition: clip-path 0.3s, opacity 0.3s;

    //stylelint-disable-next-line
    html[data-theme="dark"] & {
      @include themify-rule('color', 'secondary', 'behind-dark');
    }
  }

  html.desktop .header:hover & {
    color: transparent;
    transition-delay: 0.2s;

    &::after {
      clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
      opacity: 1;
    }
  }
}

.word {
  display: inline-block;
}

html.desktop .plus-icon {
  opacity: 0;
  transform: translateY(20px);
  transition-duration: 0.3s;
  transition-property: opacity, transform;
}

html.desktop {
  // .header:hover {
  //   .plus-icon {
  //     @include themify-rule('color', 'secondary', 'behind');
  //     //stylelint-disable-next-line
  //     html[data-theme="dark"] & {
  //       @include themify-rule('color', 'secondary', 'behind-dark');
  //     }
  //   }
  // }

  .header.open,
  .header:hover {
    .plus-icon {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.intro-content p {
  margin-bottom: rem(8px);
}

.image-header {
  position: absolute;
  z-index: 0;
  right: 10%;
  bottom: 0;

  display: none;
  overflow: hidden;

  width: 30%;

  padding: rem(80px 0 0);

  clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  pointer-events: none;

  transition: transform 0.6s, clip-path 0.6s, opacity 0.3s;

  html.desktop & {
    display: block;

    transform: translateY(25%);
  }

  img {
    transform: scale(1);
    transform-origin: center center;
    transition: transform 0.5s;
  }

  // .header.open &,
  html.desktop .header:hover & {
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);

    transition-delay: 0.4s;

    // stylelint-disable-next-line
    img {
      transform: scale(1.5);
      transition-delay: 0.3s;
    }
  }
}

.image-content {
  display: block;

  html.desktop & {
    display: none;
  }
}

.video-wrapper {
  position: relative;

  width: 100%;
  height: 0;

  padding-bottom: 56.25%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
