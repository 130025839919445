var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Behind)?_c('div',[_c('HeroText',{attrs:{"title":_vm.Behind.behindTitle,"subtitle":_vm.Behind.behindSubtitle}}),_vm._v(" "),_vm._l((_vm.Behind.behindContent),function(ref,i){
var title = ref.title;
var text = ref.text;
var reverse = ref.reverse;
return _c('div',{key:i,ref:"contents",refInFor:true,staticClass:"container mb-8 md:mb-16"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 mb-8 flex flex-col justify-end",class:{
          'lg:order-2' : reverse,
          'offset-xl-1': reverse
        }},[_c('h3',{staticClass:"big text-secondary"},[_vm._l((title.split(' ')),function(word,i){return [_c('span',{key:i,staticClass:"word stagger"},[_vm._v("\n              "+_vm._s(word)+"\n            ")]),_vm._v(" "),_c('span',{key:'space'+i,staticClass:"whitespace"})]})],2)]),_vm._v(" "),_c('div',{staticClass:"col-lg-6 col-xl-5 mb-8 flex flex-col justify-end",class:{
          'lg:order-1' : reverse,
          'offset-xl-1': !reverse
        }},[_c('MarkdownBlock',{staticClass:"intro-content stagger",attrs:{"text":text}})],1)])])}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('h4',{staticClass:"label text-secondary"},[_vm._v(_vm._s(_vm.Behind.behindJuryTitle))])]),_vm._v(" "),_c('AccordionList',{attrs:{"list":_vm.Behind.behindJuryList},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('h3',{staticClass:"big stagger",attrs:{"data-name":item.name}},[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('div',{staticClass:"plus-icon"}),_vm._v(" "),_c('div',{staticClass:"image-header",style:({'z-index': index + 2})},[_c('ResponsiveImage',{attrs:{"data":item.image}})],1)]}},{key:"content",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('ResponsiveImage',{staticClass:"image-content",attrs:{"data":item.image}}),_vm._v(" "),_c('MarkdownBlock',{staticClass:"pt-8",attrs:{"text":item.description}}),_vm._v(" "),(item.video)?_c('div',{staticClass:"video-wrapper mt-8"},[_c('VideoPlayer',{ref:("video" + index),attrs:{"video":item.video},on:{"playing":function($event){return _vm.onPlaying(index)}}})],1):_vm._e()]}}],null,false,2415875597)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }